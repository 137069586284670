// import { Uploader } from 'plupload/js/plupload.dev'
import { Uploader } from 'plupload/js/plupload.full.min.js'

const FILE_EXTENSION_ERROR_CODE = -601
const FILE_SIZE_ERROR_CODE = -600
const MAX_FILE_SIZE = '1024mb'

export default class PluploadWrapper {
  constructor (options, actions) {
    this.actions = actions
    this.options = options
    const params = {
      runtimes: 'html5,flash,silverlight,gears,browserplus',
      url: this.options.url,
      browse_button: this.options.buttonId,
      drop_element: this.options.dropElementId,
      multipart_params: this.options.params,
      file_data_name: 'file',
      send_file_name: false,
      multi_selection: this.options.multiple,
      filters: {
        mime_types: this.options.onlyImages ? [{ title: 'Image Files', extensions: 'jpg,jpeg,png,heic,heif' }] : [{ title: 'All files', extensions: '*' }],
      },
    }
    // save original key, so we can reuse it later
    this.originalKey = params.multipart_params.key
    this.uploader = new Uploader(params)
    this.uploader.init()

    this.uploader.bind('BeforeUpload', this._handleBeforeUpload)
    this.uploader.bind('FilesAdded', this._handleFilesAdded)
    this.uploader.bind('FileUploaded', this._handleFileUploaded)
    // this.uploader.bind('UploadComplete', this._handleUploadComplete) // NOT USED with redux
    this.uploader.bind('Error', this._handleError)
  }

  _handleBeforeUpload = (_, file) => {
    if (!this.originalKey) { return } // not S3 uploads

    // add unique file id to each key to avoid filename collisions, because files
    // are uploaded to the same folder, and iOS likes giving all filenames like
    // "image.jpeg".
    // Also replace all non alphanum characters with underscores to avoid issues
    // with invalid URLs
    const key = this.originalKey.replace('/${filename}', `/${file.id}/${file.name.replace(/[^A-Za-z0-9\.]/g, '_')}`)
    this.uploader.settings.multipart_params.key = key
  }

  // Fires after files were filtered and added to the queue.
  _handleFilesAdded = (_, files) => {
    const maxFiles = this.options.maxFiles || Infinity
    const currentFiles = this.uploader.files.length - files.length
    
    if (currentFiles + files.length > maxFiles) {
      window.toastr.error(I18n.t('uploader.errors.max_files_exceeded', { max: maxFiles }))
      return
    }
    
    this.actions.addNewFiles(
      this.options.buttonId,
      files.map(f => ({ id: f.id, name: f.name, _new: true }))
    )
    this.uploader.start()
  }

  // Fires when a file is successfully uploaded.
  _handleFileUploaded = (_, file, response) => {
    let url
    if (this.options.backend === 's3') {
      url = decodeURIComponent(response.response.match(/<Location>(.*)<\/Location>/)[1])
    } else {
      url = JSON.parse(response.response).url
    }
    // console.log('before files uploaded ', this.options.buttonId)
    this.actions.fileUploaded(this.options.buttonId, { id: file.id, url }, this.uploader.total)
  }

  // NOT USED with redux
  // _handleUploadComplete = (uploader, files) => null//this.actions.handleUploadComplete(this.options.buttonId, files)//this.store.handleClientUploadComplete()

  _handleError = (_, error) => {
    if (error.code === FILE_EXTENSION_ERROR_CODE) {
      window.toastr.error(I18n.t('uploader.errors.type_missmatch', { name: error.file.name }))
    }
    if (error.code === FILE_SIZE_ERROR_CODE) {
      window.toastr.error(I18n.t('uploader.errors.max_file_size_exceeded', { name: error.file.name, max_size: MAX_FILE_SIZE }))
    }
    console.error(error)
  }

  handleUploadingCancel = (id) => {
    this.uploader.removeFile(this.uploader.getFile(id))
    if (this.uploader.state === plupload.STARTED) {
      this.uploader.stop()
      this.uploader.start()
    }
  }
}
