import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { pluploadWrapperActions, fileActions, types } from 'actions/uploader_actions'
import { createLoadingSelector } from 'libs/requests_status_handlers'
import PluploadWrapper from './plupload_wrapper'
import Views from './views'

const pluploadParamsFromProps = props => ({
  ...props.backendOptions,
  buttonId: props.buttonId,
  dropElementId: props.dropElementId,
  multiple: props.multiple,
  onlyImages: props.onlyImages,
  maxFiles: props.maxFiles,
})

export const Uploader = (props) => {

  const { viewComponent, view, pluploadWrapperActions } = props
  const View = viewComponent || Views[view]

  const pluploadWrapper = useRef(null)

  useEffect(() => {
    const options = pluploadParamsFromProps(props)
    pluploadWrapper.current = new PluploadWrapper(options, { ...props.pluploadWrapperActions })
  }, [])

  useEffect(() => {
    pluploadWrapper.current.actions = pluploadWrapperActions
  }, [pluploadWrapperActions])

  return <View {...props} />
}

const viewPropTypesCheck = (props, propName, componentName) => {
  if (!props.view && !props.viewComponent) {
    return new Error(`One of 'view' or 'viewComponent' is required by '${componentName}' component.`)
  }

  if (props.view) {
    PropTypes.checkPropTypes(
      { view: PropTypes.oneOf(['FormInput', 'Album', 'ThumbnailsCollection']) },
      { view: props.view }, propName, componentName,
    )
  }
}

Uploader.propTypes = {
  view: viewPropTypesCheck,
  viewComponent: viewPropTypesCheck,
  pluploadWrapperActions: PropTypes.shape({
    addNewFiles: PropTypes.func.isRequired,
    fileUploaded: PropTypes.func.isRequired,
  }),
  fileActions: PropTypes.shape({
    removeFile: PropTypes.func,
    updateFile: PropTypes.func,
    reorderFiles: PropTypes.func,
  }),
}

const isReqStateInProgress = createLoadingSelector([types.AUTOSAVE_ATTACHMENTS, types.DESTROY_ATTACHMENT])

export default connect((state, props) => {
  const uploaderData = state.getIn(['uploader', props.buttonId])?.toJS() || {}
  return Object.assign({}, {
    isReqStateInProgress: isReqStateInProgress(state) || uploaderData.state === 'uploading',
    backendOptions: state.get('uploaderOptions').toJS(),
    ...uploaderData,
  }, props)
}, (dispatch, props) => {
  return ({
    pluploadWrapperActions: props.pluploadWrapperActions || bindActionCreators(pluploadWrapperActions, dispatch),
    fileActions: props.fileActions || bindActionCreators(fileActions, dispatch)
  })
})(Uploader)
